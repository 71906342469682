import React from 'react';

import '../header/header.scss';
import logo from '../../static/home/DJM-Logo@2x.png';
import TelIocn from '../../static/home/tel-icon2@2x.png';
import QrImg from '../../static/home/qrcode-logo@2x.png';


import MetisPositive from '../../static/productService/metis_positive@2x.png';
import MetisSide from '../../static/productService/metis_side@2x.png';
import MaomiPositive from '../../static/productService/maomi_positive@2x.png';

interface Props extends React.Props<any> {
    active: any
}

class headerCus extends React.Component<Props,any> {
    // private backgroundColor: any;
    constructor(Props: any) {
        super(Props);
        this.state = {active: Props.active,scrollTop: 0};
        // This binding is necessary to make `this` work in the callback /productService.html
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        // console.log(document.documentElement.scrollTop,`background-color: rgba(28,28,28,0.5);`);
        this.setState({
            scrollTop: document.documentElement.scrollTop
        })
    }
    changeMargin = function (){
        //获取网页可见区域宽度
        var docWidth = document.documentElement.clientWidth;
        var remRoot = docWidth > 1024 ? docWidth/1920*100 : 1024/1920*100
        document.documentElement.style.fontSize = remRoot + "px"
        // console.log(docWidth,remRoot,document.documentElement.style.fontSize)
    };
    componentWillMount(){
        this.changeMargin();
        window.onresize = this.changeMargin;
    }
    scrollTopZero(){
        document.documentElement.scrollTop = 0
    }
    render() {
        return (
            <>
                <div id="header" style={{backgroundColor: `rgba(28,28,28,${this.state.scrollTop})`}}>
                <div className="logo-name">
                    <img className="logo" src={logo} alt=""/>
                    <span className="name">小桃花AIoT开放平台</span>
                </div>
                <div className="navMeun">
                    <a className={"to-like " + (this.state.active === 1?"active":"")} href="/">首页</a>
                    <div className={"to-like productService " + (this.state.active === 2?"active":"")}>
                        产品服务
                        <div className={"pop-up"}>
                            <div className={"product-img-box"}>
                                {[{imgSrc:MetisSide,name: "Metis"},{imgSrc:MetisPositive,name: "M1"},{imgSrc:MaomiPositive,name: "M2"},{imgSrc:MaomiPositive,name: "M3"}]
                                    .map((item,index)=>{
                                        return (
                                            <div className={"product-img-item"} key={index}>
                                                <img src={item.imgSrc} alt=""/>
                                                <span>{item.name}</span>
                                            </div>
                                        )
                                    })}
                            </div>
                            <div className={"face-technology"}>
                                <strong className={"face-technology-title"}>人脸技术</strong>
                                <div className={"face-technology-name-box"}>
                                    {["人脸检测","人脸检测","人脸检测","人脸关键点检测","人脸属性分析","人脸属性分析","五官分析","头发分析",
                                        "头发分析","皮肤分析","五官分割","五官分割"].map((item,index)=>{
                                            return (
                                                <a className={"face-technology-name"} key={index} href={"/productService.html"}>
                                                    <div>{item}</div>
                                                    <div className={"exclusive"}>独家</div>
                                                </a>
                                            )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className={"to-like " + (this.state.active === 3?"active":"")} href="/solution.html">解决方案</a>
                    {/*<a className={"to-like " + (this.state.active === 4?"active":"")} href="/openPlatform.html">开放平台</a>*/}
                    <a className={"to-like " + (this.state.active === 5?"active":"")} href="/aboutUs.html">关于我们</a>
                </div>
                {/*<div className="login-register">*/}
                {/*    <span className="login">*/}
                {/*        <a href="http://iot.idankee.com/login.html" rel="noopener noreferrer" target="_blank">登陆</a>*/}
                {/*    </span>*/}
                {/*    <span className={"dividing-line"}></span>*/}
                {/*    <span className="register">*/}
                {/*        <a href="http://iot.idankee.com/registerretrievepassword.html?key=register" rel="noopener noreferrer" target="_blank">注册</a>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className="language">*/}
                {/*    <span className="cn">CN/EN</span>*/}
                {/*    /!*<span className="en">EN</span>*!/*/}
                {/*</div>*/}
                <div className={"tel"}>
                    <img src={TelIocn} alt=""/>0755-8867 4958
                </div>
            </div>
                <div className="dev-ext-container" style={{top: "259px"}}>
                    {/*<a href="/console/inbox/" target="_blank"*/}
                    {/*   className="ext-inbox-entrance"*/}
                    {/*   style={{display:"none"}}><span*/}
                    {/*    className="ext-inbox-unread-count"></span></a>*/}
                    <div className="ext-top" onClick={this.scrollTopZero}></div>
                   {/* <div className="ext-feedback">*/}
                   {/*     <div>问题反馈</div>*/}
                   {/*</div>*/}
                    <div className="ext-contact">
                        <div className="ext-contact-text">联系我们</div>
                   </div>
                    <div className="ext-qrcode">
                        <div className="img-container">
                            <img src={QrImg} alt=""/>
                            <div>官方微信扫描关注</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default  headerCus
