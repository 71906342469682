import React from 'react';
import HomeEnter from '../../static/home/Home_Enter-icon@2x.png';

class AccessButton extends React.Component {
    constructor(Props: any) {
        super(Props);
        this.state = {};
        // This binding is necessary to make `this` work in the callback
    }
    render() {
       const style = {
            button: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "1.61rem",
                height: "0.5rem",
                borderRadius: "0.1rem",
                fontSize: "0.2rem",
                color: "#4d4d4d",
                fontStretch: "normal",
                lineHeight: "0.36rem",
                letterSpacing: "0rem",
                backgroundColor: "#ffffff"
            },
            icon: {
                backgroundImage: 'url(' + HomeEnter + ')',
                backgroundSize: "100% 100%",
                backgroundRepeat: "norepeat",
                width: "0.2rem",
                height: "0.18rem",
                marginLeft: "0.1rem"
            }
        }
        return (
            // rel="noopener"。要明确选择加入window.opener，请使用rel="opener"
            <a href="http://iot.idankee.com/login.html" rel="noopener noreferrer" target="_blank"><button style={style.button}>立即接入 <i style={style.icon}></i></button></a>
        )
    }
}

export default  AccessButton
