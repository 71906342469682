import React from 'react';
import './footer.scss';
import qrcode from '../../static/home/qrcode-logo@2x.png';

interface Props {
    name: string;
}
class footerCus extends React.Component<Props,{}> {
    render() {
        return (
            <div id="footer">
                <div className="centent">
                    <div className="left">
                        <div className="resources">
                            <strong>资源</strong>
                            <span>接入文档</span>
                            <span>常见问题</span>
                        </div>
                        <div className="resources">
                            <strong>关于</strong>
                            <span>关于我们</span>
                            <span>隐私政策</span>
                            <span>用户协议</span>
                        </div>
                        <div className="resources">
                            <strong>友情链接</strong>
                            <span>皮肤数据中心</span>

                            <span>物联网数据中心</span>
                            <span>智能镜配件运营中心</span>
                        </div>
                        <div className="resources">
                            <strong>联系我们</strong>
                            <span>电话（微信）：18028003239</span>
                            <span>公司地址：深圳南山区北理工创新大厦501</span>
                        </div>
                    </div>
                    <div className="right">
                        <div className="idankee-img">
                            <img src={qrcode} alt=""/>
                            <span>蛋壳物联官方客服</span>
                        </div>
                        <div className="public-img">
                            <img src={qrcode} alt=""/>
                            <span>公众号</span>
                        </div>
                    </div>
                </div>
                <div className={"right-reserved"}>
                    Copyright © DJM 小桃花 All Right Reserved | 粤ICP备2020073603号
                </div>
            </div>
        );
    }
}

export default  footerCus
