import React from 'react';
// import logo from '../logo.svg';

/**
 * @Description: AI检测识别技术Icon
 */
import FaceRecognition from '../../static/home/Face-recognition-icon@2x.png';
import FaceRecognitionActive from '../../static/home/Face-recognition-icon-active@2x.png';
import SkinTestIcon from '../../static/home/skin-test-icon@2x.png';
import SkinTestIconActive from '../../static/home/skin-test-icon-active@2x.png';
import PetectionIcon from '../../static/home/pathological-detection-icon@2x.png';
import PetectionIconActive from '../../static/home/pathological-detection-icon-active@2x.png';
import BodyDetectionIcon from '../../static/home/Body-detection-icon@2x.png';
import BodyDetectionIconActive from '../../static/home/Body-detection-icon-active@2x.png';
import ImageRecognitionIcon from '../../static/home/Image-recognition-icon@2x.png';
import ImageRecognitionIconActive from '../../static/home/Image-recognition-icon-active@2x.png';


import FaceDetection from '../../static/home/Face_detection@2x.png';
import FaceKeyPoints from '../../static/home/FaceKeyPoints@2x.png';
import FaceAttributeAnalysis from '../../static/home/Face_attribute_analysis@2x.png';
import './App.scss';
import FooterCus from '../../component/footer/footer'
import HeaderCus from '../../component/header/header'
import Swiper from '../../component/swiper/swiper'
import AccessButton from '../../component/widget/AccessButton'


import HomeBanner from '../../static/home/Home_Banner@2x.png';
import StandardProtocolIcon from '../../static/home/Standard-protocol-icon@2x.png';
import BillionsSupport from '../../static/home/Billions-support@2x.png'
import Authentication from '../../static/home/authentication@2x.png'
import Technology from '../../static/home/technology@2x.png'


import enterpriseServiceIcon from '../../static/home/enterprise-service-icon@2x.png';
import BeautyMakeupStores from '../../static/home/Beauty_makeup_stores@2x.png';
import HealthAndBeautyIcon from '../../static/home/Health-and-beauty-icon@2x.png';
import HardwareFacilitiesIcon from '../../static/home/Hardware-facilities-icon@2x.png';
import InternetThingsIcon from '../../static/home/Internet-things-icon@2x.png';
import WEBAPIIllustrator from '../../static/home/WEB-API-Illustrator@2x.png';
import MobileSDKIllustrator from '../../static/home/Mobile-SDK-Illustrator@2x.png';
import SloveAppIllustrator from '../../static/home/Slove-app-Illustrator@2x.png';
import partnersImg from '../../static/home/partners@2x.png';
import enterpriseService from '../../static/home/1.enterprise-service.png';
import PicturesBeautyShop from '../../static/home/2.Pictures-beauty-shop.png';
import MedicalCosmetologyPictures from '../../static/home/3.Medical-cosmetology-pictures.png';
import PictureHardwareFacilities from '../../static/home/4.Picture-hardware-facilities.png';
import InternetThingsPictures from '../../static/home/5.Internet-things-pictures.png';
import Mask from '../../static/home/mask.png';

interface AppState {
    aiDetectionContainerActive: number,
    aiDetectionContainer: any
}

class App extends React.Component<{}, AppState> {
    constructor(Props: any) {
        super(Props)
        this.state = {
            aiDetectionContainerActive: 0,
            aiDetectionContainer: [
                {
                    name: "人脸识别", imgSrc: FaceRecognition, activeImgSrc: FaceRecognitionActive,
                    centent: [
                        {
                            name: "人脸识别",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "人脸关键点检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "人脸属性分析",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        }, {
                            name: "人脸检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "人脸关键点检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "人脸属性分析",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        },]
                },
                {
                    name: "皮肤检测", imgSrc: SkinTestIcon, activeImgSrc: SkinTestIconActive,
                    centent: [
                        {
                            name: "皮肤检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "皮肤检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "皮肤检测",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        }, {
                            name: "皮肤检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "皮肤检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "皮肤检测",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        },]
                },
                {
                    name: "病理检测", imgSrc: PetectionIcon, activeImgSrc: PetectionIconActive,
                    centent: [
                        {
                            name: "病理检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "病理检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "病理检测",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        }, {
                            name: "病理检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "病理检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "病理检测",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        },]
                },
                {
                    name: "形体检测", imgSrc: BodyDetectionIcon, activeImgSrc: BodyDetectionIconActive,
                    centent: [
                        {
                            name: "形体检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "形体检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "形体检测",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        }, {
                            name: "形体检测",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "形体检测",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "形体检测",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        },]
                },
                {
                    name: "图像识别", imgSrc: ImageRecognitionIcon, activeImgSrc: ImageRecognitionIconActive,
                    centent: [
                        {
                            name: "图像识别",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "图像识别",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "图像识别",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        }, {
                            name: "图像识别",
                            centent: "检测并定位图像中的人脸，输出 包围每个人脸的方框坐标",
                            imgSrc: FaceDetection,
                        }, {
                            name: "图像识别",
                            centent: "检测并定位人脸118个关键点坐标，几乎\n" +
                                "所有人脸编辑功能都需要用到此技术。",
                            imgSrc: FaceKeyPoints,
                        }, {
                            name: "图像识别",
                            centent: "识别性别、年龄、情绪，以及妆容、\n" +
                                "颜值、眼镜等面部属性。",
                            imgSrc: FaceAttributeAnalysis,
                        },]
                },
            ]


        }
    }

    componentWillMount() {

    }

    handleMouseUserOver(index: any) {
        this.setState({
            aiDetectionContainerActive: index
        })
    }

    handleMouseOut() {
        console.log('handleMouseOut')
    }

    bannerContainer() {
        const views: any = [];
        [HomeBanner, HomeBanner, HomeBanner, HomeBanner, HomeBanner].forEach((imgSrc,index) => {
            views.push(
                <div className="swiper-slide" key={index}>
                    <div className={"ad"}>
                        <strong>DJM VisionSeed智能视觉模组</strong>
                        <span>AI算法+算力+摄像头一体化，VisionSeed让AI视觉触手可及</span>
                    </div>
                    <img src={imgSrc} alt=""/>
                </div>
            )
        })
        return views;
    }

    aiDetectionContainer() {
        const views: any = [];
        this.state.aiDetectionContainer[this.state.aiDetectionContainerActive].centent.forEach((item: any, index: number) => {
            views.push(
                <div className="swiper-slide" key={index}>
                    <div className={"bottom-item " + (!index ? "active" : "")} key={index}>
                        <img src={item.imgSrc} alt=""/>
                        <div className="name">
                            <strong>{item.name}</strong>
                            <AccessButton/>
                        </div>
                        <p>{item.centent}</p>
                    </div>
                </div>
            )
        })
        return views;
    }

    render() {
        return (
            <div id="index" ref={"index"}>
                <HeaderCus active={1}/>
                <Swiper domClass={'banner-container'} domList={this.bannerContainer()} option={{
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                }}/>
                <div className="ai-detection-identification">
                    <div className="title">AI检测识别技术</div>
                    <div className="tab-nav">
                        {this.state.aiDetectionContainer.map((item: any, index: any) => {
                            return (
                                <div
                                    className={"tab-item " + (this.state.aiDetectionContainerActive === index ? "active" : "")}
                                    key={index}
                                    onMouseOver={() => {
                                        this.handleMouseUserOver(index)
                                    }}
                                >
                                    <img
                                        src={this.state.aiDetectionContainerActive === index ? item.activeImgSrc : item.imgSrc}
                                        alt=""/>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div className="bottom">
                        <Swiper domClass={'ai-detection-container'}
                                domList={this.aiDetectionContainer()} option={{
                            // loop: true,
                            on: {
                                transitionEnd: function () {
                                    // @ts-ignore
                                    console.log(this.activeIndex)
                                },
                            },
                            autoplay: true,
                            slidesPerView: 3,
                            spaceBetween: 30,
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                            }
                        }}/>
                    </div>
                    {/*</div>*/}
                </div>
                <div className="core-strengths">
                    <div className="title">核心优势</div>
                    <div className="core-strengths-box">
                        {[
                            {
                                name: "标准协议",
                                centent: "支持MQTT/CoAP等物联网\n" +
                                    "                                        协议实现设备与云端双向\n" +
                                    "                                        连接和控制。",
                                imgSrc: StandardProtocolIcon
                            }, {
                                name: "百亿数据支撑",
                                centent: "算法的落地场景丰富，用户\n" +
                                    "反馈广泛且快速。独有的海\n" +
                                    "量数据，提供了强大的支撑。",
                                imgSrc: BillionsSupport
                            }, {
                                name: "数亿用户验证",
                                centent: "前沿技术能快速与产品结合，\n" +
                                    "日调用量逾亿次，兼具稳定性\n" +
                                    "与实用性，是您可靠的选择。",
                                imgSrc: Authentication
                            }, {
                                name: "技术艺术交融",
                                centent: "拥有长期的审美积累，艺术\n" +
                                    "基因深植其中，能够把握美\n" +
                                    "的趋势，实现艺术与技术的\n" +
                                    "有机结合。",
                                imgSrc: Technology
                            },
                        ].map((item, index) => {
                            return (
                                <div className="centent-box" key={index}>
                                    <img className="centent-img" src={item.imgSrc} alt=""/>
                                    <div className="centent-name">{item.name}</div>
                                    <div className="centent">
                                        {item.centent}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="application-scenarios">
                    <div className="top-title">丰富业务场景 助力行业前行</div>
                    <div className="bottom-centent">
                        {[
                            {
                                name: "企业服务",
                                bgSrc: {
                                    backgroundImage: 'url(' + enterpriseService + ')'
                                },
                                imgSrc: enterpriseServiceIcon,
                                centent: "提供多样化解决方案\n" +
                                    "协助企业实现业务升级"
                            }, {
                                name: "美妆门店",
                                bgSrc: {
                                    backgroundImage: 'url(' + PicturesBeautyShop + ')'
                                },
                                imgSrc: BeautyMakeupStores,
                                centent: "精准分析   智能试妆\n" +
                                    "丰富技术实现智能线下美业"
                            }, {
                                name: "医疗美容",
                                bgSrc: {
                                    backgroundImage: 'url(' + MedicalCosmetologyPictures + ')'
                                },
                                imgSrc: HealthAndBeautyIcon,
                                centent: "独家AI皮肤分析\n" +
                                    "打造智慧医美门店"
                            }, {
                                name: "硬件设施",
                                bgSrc: {
                                    backgroundImage: 'url(' + PictureHardwareFacilities + ')'
                                },
                                imgSrc: HardwareFacilitiesIcon,
                                centent: "丰富自研AI技术\n" +
                                    "助力硬件产品智能化",
                            }, {
                                name: "物联网",
                                bgSrc: {
                                    backgroundImage: 'url(' + InternetThingsPictures + ')'
                                },
                                imgSrc: InternetThingsIcon,
                                centent: "精准识别及精细化影像处理\n" +
                                    "灵活适配各类业务场景"
                            },
                        ].map((item, index) => {
                            return (
                                <div className="bottom-item" key={index} style={item.bgSrc}>
                                    <img className={"mask"} src={Mask} alt=""/>
                                    <img className={"img"} src={item.imgSrc} alt=""/>
                                    <span className={"name"}>{item.name}</span>
                                    <p className={"centent"}>{item.centent}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="adapt-scene">
                    <div className="top-title">多样接入方式 灵活适配场景</div>
                    <div className="bottom-centent">
                        {
                            [
                                {
                                    name: "WEB API",
                                    centent: "通过Web API调用，快速灵活接入DJM   AI开放平台的算法服务。",
                                    imgSrc: WEBAPIIllustrator
                                }, {
                                name: "MOBILE SDK",
                                centent: "集成Mobile SDK到您的应用中，接入各种离线功能和实时算法。",
                                imgSrc: MobileSDKIllustrator
                            }, {
                                name: "行业解决方案",
                                centent: "根据您的业务场景和需求，为您定制完整的软硬件解决方案。",
                                imgSrc: SloveAppIllustrator
                            },
                            ].map((item, index) => {
                                return (
                                    <div className="bottom-item" key={index}>
                                        <img className={"img"} src={item.imgSrc} alt=""/>
                                        <strong className={"name"}>{item.name}</strong>
                                        <div className={"centent"}>{item.centent}</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className="partners">
                    <div className="top-title">合作伙伴</div>
                    <div className="bottom-centent">
                        <img src={partnersImg} alt=""/>
                    </div>
                </div>
                <FooterCus name="FooterCus"/>
            </div>
        );
    }
}

export default App;
